$.fn.cobSlider = function(min, max, step) {

    var _jQSliderElementId;
    var _inputFieldId;

    var _view = {
        createSliderContainer : function(div, elemId) {
            var html = "<div class='slider'></div>" +
                    "<input class='cob-field slider-value' type='text' id='" + elemId + "'/>";

            var $tmpl = $(html);
            $tmpl.appendTo($(div));
        },

        createJQSlider: function(divId, inputId, min, max, step) {
            $(divId).slider({
                min:(min ? parseInt(min) : 0),
                max:(max ? parseInt(max) : 100),
                step:(step ? parseFloat(step) : 1),
                disabled: false,

                slide: function(event, ui) {
                    $(inputId).val(ui.value);
                }
            });
        }
    };

    var _createCobSlider = function($div) {
        var oldId = $div.attr("id");
        _inputFieldId = oldId.replace(".", "\\.");

        $div.attr("id", "slider-wrapper-" + oldId);

        _jQSliderElementId = "#slider-wrapper-" + _inputFieldId + " div.slider";

        _view.createSliderContainer($div, oldId);
        _view.createJQSlider(_jQSliderElementId, "#" + _inputFieldId, min, max, step);

        $("#" + _inputFieldId).bind('keyup paste', function(event) {
            $(_jQSliderElementId).slider("value", event.target.value);
        });
    };

    var _setValueOnSlider = function(value) {
        $(_jQSliderElementId).slider("value", value);
        $("#" + _inputFieldId).val(value);
    };

    var _getValueFromSlider = function() {
        return $("#" + _inputFieldId).val();
    };

    //Perform initialization
    _createCobSlider($(this));

    return {
        setValue: _setValueOnSlider,
        getValue: _getValueFromSlider
    }
};
