$.fn.cobDateField = function() {

    var _inputFieldId;

    var _isValidNumber = function(value) {
        return !isNaN(+value);
    };

    var _setValue = function(value) {
        if (value !== '' && _isValidNumber(value)) {
            _view.setDate(+value);
        } else {
            _view.clear();
        }
    };

    var _view = (function() {

        var _bindFocusIn = function() {
            $("#" + _inputFieldId).bind("focusin", function() {
                $("#" + _inputFieldId).val(_getDateFromDisplay());
            });
        };

        var _bindFocusOut = function() {
            $("#" + _inputFieldId).bind('focusout', function() {
                _setValue(_getDateFromDisplay());
            });
        };

        var _setDate = function(realVal) {
            $("#" + _inputFieldId).val(_formatDateForDisplay(realVal));
        };

        var _formatDateForDisplay = function(realVal) {
            return sprintf('%2d/', realVal / 10000).replace(' ', '0') +
                    sprintf('%2d/', realVal % 10000 / 100).replace(' ', '0') +
                    sprintf('%2d', realVal % 10000).replace(' ', '0');
        };

        var _getDateFromDisplay = function() {
            var realVal = $("#" + _inputFieldId).val();
            if (realVal !== '') {
                return realVal.replace('/', '');
            } else {
                return realVal;
            }
        };

        var _getDay = function() {
            _getDateFromDisplay() / 10000;
        }

        var _getMonth = function() {
            _getDateFromDisplay() % 10000 / 100;
        }

        var _getYear = function() {
            _getDateFromDisplay() % 10000;
        }

        return{
            bindBehaviour: function() {
                _bindFocusIn();
                _bindFocusOut();
            },
            setDate : _setDate ,
            getDate : _getDateFromDisplay,
            getDay: _getDay,
            getMonth : _getMonth,
            getYear:  _getYear,
            clear: function() {
                $("#" + _inputFieldId).val('');
            }
        }
    }());

    var _createDateField = function(_$el) {
        _inputFieldId = _$el.attr("id");
        _view.bindBehaviour();
    };
    _createDateField($(this));

    return {
        setValue: _setValue,
        getValue: _view.getDate,
        getDay: _view.getDay,
        getMonth : _view.getMonth,
        getYear:  _view.getYear
    }
};