$.fn.cobTimeField = function() {

    var _inputFieldId;

    var _isValidNumber = function(value){
        return !isNaN(+value);
    };

    var _setValue=function(value){
        if(value !== '' && _isValidNumber(value)){
            _view.setTime(+value);
        }else{
            _view.clear();
        }
    };

    var _view = (function() {

        var _bindFocusIn = function() {
            $("#" + _inputFieldId).bind("focusin", function() {
                $("#" + _inputFieldId).val(_getTimeFromDisplay());
            });
        };

        var _bindFocusOut = function() {
            $("#" + _inputFieldId).bind('focusout', function() {
                _setValue(_getTimeFromDisplay());
            });
        };

        var _setTime = function(realVal) {
            $("#" + _inputFieldId).val(_formatTimeForDisplay(realVal));
        };

        var _formatTimeForDisplay = function(realVal) {
            return sprintf('%2d:', realVal / 100).replace(' ', '0') +
                    sprintf('%2d', realVal % 100).replace(' ', '0');
        };

        var _getTimeFromDisplay = function() {
            var realVal = $("#" + _inputFieldId).val();
            if (realVal !== '') {
                return realVal.replace(':', '');
            } else {
                return realVal;
            }
        };

        return{
            bindBehaviour: function() {
                _bindFocusIn();
                _bindFocusOut();
            },
            setTime : _setTime ,
            getTime : _getTimeFromDisplay,
            clear: function() {
                $("#" + _inputFieldId).val('');
            }
        }
    }());

    var _createTimeField = function(_$el) {
        _inputFieldId = _$el.attr("id");
        _view.bindBehaviour();
    };
    _createTimeField($(this));

    return {
        setValue: _setValue,
        getValue: _view.getTime
    }
};