$.fn.collapsable =
    function(buttonSelector, targetSelector, options) {

        var settings = {
            expandFn:function() {
                window.console.log('cob-collapsable: expanding ' + targetSelector);
            },
            collapseFn:function() {
                window.console.log('cob-collapsable: collapsing ' + targetSelector);
            },
            buttonAnimationClass:'.none'
        }

        var _$that = $(this),
            _stateKey = 'collapser-' + buttonSelector + "-" + targetSelector,
            _$button = _$that.find(buttonSelector),
            _$target = _$that.find(targetSelector),

            enabled = false;

        var _enable = function(collapsed) {
            if (enabled) return;
            enabled = true;

            if (collapsed !== 'undefined' && collapsed !== null) {
                _setState(collapsed);
            }

            _$button.click(function() {
                var inState = _$that.data(_stateKey);
                _setState(!inState);
            });
        };

        var _disable = function(collapsed) {
            if (!enabled)return;
            enabled = false;

            if (collapsed !== 'undefined' && collapsed !== null) {
                _setState(collapsed);
            }

            _$button.unbind('click');
        }

        var _setState = function(state) {
            if (state === _$that.data(_stateKey))
                return;

            if (state) {
                _$button.removeClass(settings.buttonAnimationClass);
                _$target.hide();
                settings.collapseFn();
            } else {
                _$button.addClass(settings.buttonAnimationClass);
                _$target.show();
                settings.expandFn();
            }

            _$that.data(_stateKey, state);
        }

        var _init = function() {
            jQuery.extend(settings, options);

            _$that.data(_stateKey, true);
            _enable(null);
        };
        _init();

        return {
            getState:function() {
                _$that.data(_stateKey);
            },
            setState:_setState,
            enable:_enable,
            disable:_disable
        }
    };