// Jquery plugin that only accepts digits in the input fields:
//
// ====================================================================
//  DEPRECATED:
//  * Use instead the $.autoNumeric. It's a more capable plugin
// ====================================================================
//
// Usage:
//    $("input.number").numeric();
// opts:
//    `decimalKeyCode`: the separator for the decimal numbers. Default to '.'
$.fn.numeric = function(opts) {

    var _opts = $.extend({decimalKeyCode: 190}, opts);

    this.each(function(idx, el) {
        $(el).keydown(function(e) {

            // Allow: backspace, delete, tab, escape, and enter
            if (e.keyCode === 46 || e.keyCode === 8 ||
                e.keyCode === 9 || e.keyCode === 27 || e.keyCode === 13 ||

                    // Allow: ctrl+a, ctrl+c, ctrl+v
                (e.keyCode === 65 && e.ctrlKey === true) ||
                (e.keyCode === 67 && e.ctrlKey === true) ||
                (e.keyCode === 86 && e.ctrlKey === true) ||

                    // Allow: cmd+a, cmd+c, cmd+v
                (e.keyCode === 65 && e.metaKey === true) ||
                (e.keyCode === 67 && e.metaKey === true) ||
                (e.keyCode === 86 && e.metaKey === true) ||

                    // Allow: home, end, left, right
                (e.keyCode >= 35 && e.keyCode <= 39) ||

                // Allow the decimal
                (e.keyCode === _opts.decimalKeyCode)) {

                // do nothing
            } else if (e.shiftKey || e.altKey ||
                       (e.keyCode < 48 || e.keyCode > 57) &&
                       (e.keyCode < 96 || e.keyCode > 105 )) {
                e.preventDefault();
            }
        });
    });
    return this;
};