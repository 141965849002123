$.fn.smartMenu = function() {

    var _$menuContainer = $(this),
        isStopped = false;

    var _resetState = function() {
        var subMenus = $(".smart-sub-menus", _$menuContainer).children("li").detach();

        _$menuContainer.find(".smart-menu").remove();
        _$menuContainer.append(subMenus);
    };

    var buildMenu = function(subMenus) {
        var $newMenu = subMenus[0].clone().addClass("smart-menu dropdown")
                .html("<a href='#' class='smart-menu-link dropdown-toggle' data-toggle='dropdown'>" +
                      "More <i class='icon-caret-down icon-large'></i>" +
                      "</a>" +
                      "<ul class='smart-sub-menus dropdown-menu'></ul>"),
            $menuList = $(".smart-sub-menus", $newMenu);

        $menuList.append(subMenus);

        return $newMenu;
    };

    var _adjustMenu = function() {
        if (isStopped || !_$menuContainer.is(":visible")) return;

        var containerWidth = _$menuContainer.width() - 83, // 83 is the size of the menu "More"
            menus = _$menuContainer.children("li:not(.smart-menu)"),
            breakingMenus = [],
            menusTotalWidth = 0;

        var $smartMenu;

        menus.each(function(idx, el) {
            var menu = $(el);

            menusTotalWidth += menu.outerWidth(true);

            if (menusTotalWidth > containerWidth) {
                breakingMenus.push(menu.detach());
            }
        });

        if (breakingMenus.length > 0) {

            if (_$menuContainer.children("li").length > 0) {
                // Removing last menu to get more room for the smart menu
                breakingMenus.unshift(_$menuContainer.children("li").last().detach());
            }

            $smartMenu = buildMenu(breakingMenus);
            _$menuContainer.append($smartMenu);
        }
    };

    var _bindEvents = function() {
        $(window).on('resize.smartMenu', function() {
            _resetState();
            _adjustMenu();
        });
    };

    var _unbindEvents = function() {
        $(window).off('resize.smartMenu');
        _$menuContainer.off("click", '.smart-menu-link');
    };

    var _stop = function() {
        _unbindEvents();
        isStopped = true;
    };

    var _start = function() {
        if (isStopped) {
            _bindEvents();
            isStopped = false;
        }
    };

    _bindEvents();
    _adjustMenu();

    return {
        stop: _stop,
        start: _start,

        reset: _resetState,
        adjust: _adjustMenu,
        isStopped: function() {
            return isStopped;
        }
    }
};
