// In case we want to introduce a validator that prevents Next or previous buttons to be executed, we may define the
// following callbacks in the page were we want it to happen:
// $('#wizard-next').data.beforeSubmitFn
// $('#wizard-previous').data.beforeSubmitFn
//
// The function defined must return true if everything is ok and the wizard can proceed or false otherwise.
// Its a good ideia to self destruct the function after successfull execution of it so it does not get executed on
// other pages of the same wizard.
//
// An example of this usage is in the
// confm/dist/src/main/etc/conf/customUI/panasonic/modify/grp_cui_admin/horarios/horarios.cobml
// where we want to validate the form before Next can really send the results to the automaton.
//validate summer dates before sending. The function has a structure like the following:
//
//$('#wizard-next').data.beforeSubmitFn = function(){
//
//    if(summer date is not valid){
//        return false;
//    }
//
//    //if successfull validation remove the callback from the wizard button so it do not mess with others
//    $('#wizard-next').data.beforeSubmitFn = undefined;
//    return true;
//};

$.fn.wizard = function(props) {
    //todo jone neste momento não pode haver mais do que 1 Wizard por página. Devia estar protegido.

    //these are "private" vars/methods
    var _that = this,
        _steps = props.steps || [],
        _currStep = 0,
        _startingStep = 0,
        _previusStep = 0;

    (function() {
        var i = 0;

        for (; i < _steps.length; i++) {
            if (!_steps[i].locked) {
                _startingStep = i;
                return;
            }
        }

    }());

    var _view = (function(){

        var html= '<div id="wizard-chrome">' +
                '    <div id="wizard-content">' +
                '        <div id="wizard-title"><h4 rel="localize[wizard.title]">' + props.title + '</h4></div>' +
                '<div id="wizard-steps-breadcrum">';

        var i = 0;
        var _step;
        for (; i < _steps.length; i++) {
            _step = _steps[i];
             html +=
            '       <span id="wizard-breadcrum-step-' + i +'" class="wizard-breadcrum-step">' +
            '           <span class="step-number">' + (i + 1) + '. </span>' +
            '           <span class="step-description" rel="localize[wizard.' + _step.i18n + ']">' + _step.description +'</span>' +
            '       </span>';
        }

        html +=
            '        </div>' +
            '        <div id="wizard-step-wrapper"></div>' +
            '    </div>' +
            '    <div id="wizard-controls">' +
            '        <button id="wizard-cancel" class="btn btn-small">' +
            '           <i class="icon-reply"></i>' +
            '           <span rel="localize[wizard.cancel]">' + props.cancel + '</span>' +
            '        </button>' +
            '        <button id="wizard-previous" class="btn btn-small">' +
            '           <i class="icon-arrow-left"></i>' +
            '           <span rel="localize[wizard.previous]">' + props.previous + '</span>' +
            '        </button>' +
            '        <button id="wizard-next" class="btn btn-small">' +
            '           <i class="icon-arrow-right"></i>' +
            '           <span rel="localize[wizard.next]">' + props.next + '</span>' +
            '        </button>' +
            '    </div>' +
            '</div>';

        var _resetBreadCrumbState = function() {
            var i= 0;
            for (; i < _steps.length; i++) {
                var el = $('#wizard-breadcrum-step-' + i);
                el.removeClass('wizard-breadcrum-step-current');
                el.removeClass('wizard-breadcrum-step-completed');
            };
        };

        return{
            init:function() {

                $(_that).append(html);

                _steps.map(function(step) {
                    if (step.content) {
                        $("div#wizard-step-wrapper").append($(step.content).addClass("wizard-step").detach().hide());
                    }
                });
            },
            updateControlStates: function() {
                //primeiro passo
                if (_currStep == _startingStep) {
                    $('button#wizard-previous').attr("disabled", true);
                } else {
                    $('button#wizard-previous').attr("disabled", false);
                }

                //último passo
                if (_currStep == _steps.length - 1) {
                    $('button#wizard-next').attr('disabled', true);
                } else {
                    $('button#wizard-next').attr('disabled', false);
                }
            },
            updateBreadCrumbState: function() {
                var i= 0;
                for (; i < _steps.length; i++) {
                    var el = $('#wizard-breadcrum-step-' + i);
                    if (i === _previusStep && i !==  _currStep) {
                        el.removeClass('wizard-breadcrum-step-current');
                    }
                    if (i === _currStep) {
                        el.addClass('wizard-breadcrum-step-current');
                        el.removeClass('wizard-breadcrum-step-completed');
                    }
                    if (_steps[i].completed && i != _currStep) {
                        el.addClass('wizard-breadcrum-step-completed');
                    }
                }
            },
            disableControls: function() {
                $('button#wizard-next').attr("disabled", true);
                $('button#wizard-previous').attr("disabled", true);
                $('button#wizard-cancel').attr("disabled", true);
            },
            resetView: function() {
                $('button#wizard-cancel').attr("disabled", false);
                _resetBreadCrumbState();
                this.updateControlStates();
                this.updateBreadCrumbState();
            },
            showStep: function(n) {
                _steps.forEach(function(step, idx) {
                    if (idx === n) {
                        $(step.content).show();
                        $(step.content).removeClass("hidden");
                    } else {
                        $(step.content).hide();
                        $(step.content).addClass("hidden");
                    }
                });
            }
        }
    }());

    var _callSeconddIfFirst = function(beforeFn, actionFn) {
        var proceed = true;

        if (beforeFn && typeof beforeFn === 'function') {
            proceed = beforeFn();
        }

        if (proceed || proceed === undefined) actionFn();
    };

    var _start = function() {
        _currStep = _startingStep;
        _previusStep = _currStep;

        _view.showStep(_currStep);
    };

    var _bindControls = function () {
        var $nextBtn = $('button#wizard-next');
        var $previousBtn = $('button#wizard-previous');
        var $cancelBtn = $('button#wizard-cancel');

        $nextBtn.bind('click', function() {

            if($nextBtn.data && $nextBtn.data.beforeSubmitFn){
                if($nextBtn.data.beforeSubmitFn()){
                    _callSeconddIfFirst(_steps[_currStep].before_next, _showNextStep);
                }
            }else{
                _callSeconddIfFirst(_steps[_currStep].before_next, _showNextStep);
            }

        });

        $previousBtn.bind('click', function() {
            if($previousBtn.data && $previousBtn.data.beforeSubmitFn){
                if($previousBtn.data.beforeSubmitFn()){
                    _callSeconddIfFirst(_steps[_currStep].before_previous, _showPreviousStep);
                }
            }else{
                _callSeconddIfFirst(_steps[_currStep].before_previous, _showPreviousStep);
            }
        });

        $cancelBtn.bind('click', function() {
            _cancelWizard();
        });
    };

    var _showNextStep = function() {

        if (_currStep > -1)
            $(_steps[_currStep].content).hide('slide', {
                direction: "left"
            }, 600);

        if (_currStep + 1 == _steps.length) {
            _finishWizard();
            return;
        }
        _previusStep = _currStep;
        _steps[_currStep].completed = true;

        $(_steps[++_currStep].content).show('slide', {
            direction: "right"
        }, 600);

        _view.updateControlStates();
        _view.updateBreadCrumbState();
    };

    var _showPreviousStep = function() {
        $(_steps[_currStep].content).hide('slide', {
            direction: "right"
        }, 600);

        _previusStep = _currStep;
        $(_steps[--_currStep].content).show('slide', {
            direction: "left"
        }, 600);

        _view.updateControlStates();
        _view.updateBreadCrumbState();
    };

    var _finishWizard = function() {
        _callSeconddIfFirst(props.onfinish, _view.disableControls);
    };

    var _cancelWizard = function() {
        _callSeconddIfFirst(props.oncancel, _view.disableControls);
    };

    var _initWizardState = function() {

        _steps.map(function(step) {
            step.completed = step.locked;
        });
    };

    return {
        init : function() {
            _view.init();
            _initWizardState();
            _start();

            var initFunction = props.oninit;
            if (initFunction && typeof initFunction == 'function') {
                initFunction();
            }
            _bindControls();
            _view.updateControlStates();
            _view.updateBreadCrumbState();
        },
        next: _showNextStep,
        previous: _showPreviousStep,
        reset:function(){
            _initWizardState();
            _start();
            _view.resetView();
        }
    }
};
