/**
 * jQuery by default doesn't support the IE8/9 XDomainRequest object so we have to add it as a ajax transport
 * for CORS support
 * rel: http://bugs.jquery.com/ticket/8283#comment:43
 */

// If XDomainRequest is available and the XMLHttpRequest object does NOT support the withCredentials method (CORS)
if ( window.XDomainRequest && !('withCredentials' in new XMLHttpRequest()) ) {

    jQuery.ajaxTransport(function( options, originalOptions, jqXHR ) {

        if ( options.crossDomain && options.async ) {

            if ( options.timeout ) {
                options.xdrTimeout = options.timeout;
                delete options.timeout;
            }

            var xdr;

            return {
                send: function( _, complete ) {
                    function callback( status, statusText, responses, responseHeaders ) {
                        xdr.onload = xdr.onerror = xdr.ontimeout = jQuery.noop;
                        xdr = undefined;
                        complete( status, statusText, responses, responseHeaders );
                    }
                    xdr = new XDomainRequest();
                    xdr.onload = function() {
                        callback( 200, "OK", { text: xdr.responseText }, "Content-Type: " + xdr.contentType );
                    };
                    xdr.onerror = function() {
                        callback( 404, "Not Found" );
                    };
                    xdr.onprogress = jQuery.noop;
                    xdr.ontimeout = function() {
                        callback( 0, "timeout" );
                    };
                    xdr.timeout = options.xdrTimeout || Number.MAX_VALUE;
                    xdr.open( options.type, options.url );
                    xdr.send( ( options.hasContent && options.data ) || null );
                },
                abort: function() {
                    if ( xdr ) {
                        xdr.onerror = jQuery.noop;
                        xdr.abort();
                    }
                }
            };
        }
    });
}
