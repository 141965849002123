/**
 * Plugin that creates radio button groups on a given div
 * It has a default radio to permit the no-selection status; all other radio btns in the group are cob-fields.
 *
 * Usage example on a cobml file:
 * <div class="cob-radio" id="R369" values='1|0' i18n-keys="on-label|off-label" i18n-deflabels="On|Off" box-width="150"></div>
 *
 * @param radioValues           the array of values for the radio buttons (values separated by |)
 * @param i18nKeys              the array of keys for internationalization (values separated by |) - optional
 * @param i18nDefaultLabels     the array of default labels (values separated by |) - optional
 * @param boxWidth              the width for the control - optional
 */
$.fn.cobRadio = function(radioValues, i18nKeys, i18nDefaultLabels, boxWidth) {

    var _groupId;
    var _$container;
    //todo jbarata tentar estimar um tamanho automaticamente com base no n� de values e se tem labels
    var _ulWidth = boxWidth ? boxWidth : 120;

    var ulBaseStyle = "height:25px; width:" + _ulWidth + "px; list-style-type:none; border:1px solid #e1e1e1;padding: 0;margin: 0;border-radius: 5px;";
    var ulselectedStyle = "background-color:#0088CC; color:#fff;";
    var ulUnselectedStyle = "background-color:#eee;";

    var liBaseStyle = "display: inline-block; vertical-align:bottom;";
    var firstLiStyle = "padding-right: 5px;padding-left: 5px;";
    var lastLiStyle = "display: inline-block;";

    var clearBtnBaseStyle = "vertical-align: middle;text-decoration: none;border-right:1px solid; padding-right: 10px; position:relative;top:1px;";
    var clearBtnSelectedStyle = "color:#ffffff; cursor: pointer;";
    var clearBtnUnselectedStyle = "color:#908f8f;";

    var _view = {

        createRadioGroup : function(div) {
            var value,labelKey, defaultLabel;
            _groupId = div.attr("id");
            _$container = $('div#' + _groupId);

            var html = "<ul style='" + ulBaseStyle + ulUnselectedStyle + "'>" +
                    "<li style='" + liBaseStyle + firstLiStyle + "'>" +
                    "<a style='" + clearBtnBaseStyle + clearBtnUnselectedStyle + "' rel='localize[action-clear]'>clear</a>" +
                    "</li>";


            if (radioValues) {
                var groupsize = radioValues.length;
                for (var i = 0; i < groupsize; i += 1) {
                    value = radioValues[i];
                    labelKey = i18nKeys[i] ? i18nKeys[i] : '';
                    defaultLabel = i18nDefaultLabels[i] ? i18nDefaultLabels[i] : '';

                    html = html +
                            "<li style='" + ((i === groupsize - 1) ? lastLiStyle : liBaseStyle) + "'> &nbsp;&nbsp;" +
                            "<span style='position:relative;top:1px;margin-right: 2px;' rel='localize[" + labelKey + "]'>" + defaultLabel + "</span>" +
                            "<input class='cob-field' type='radio' value='" + value + "' name='" + _groupId + "' title='" + value + "'/>" +
                            "</li>";
                }
            }

            html = html + "</ul>";


            var $tmpl = $(html);
            $tmpl.appendTo($(div));


            _$container.find('a').each(function(idx, el) {
                $(el).bind('click', function() {
                    _view.setUnselectedState();
                });
            });

            _$container.find('input[type="radio"].cob-field').each(function(idx, el) {
                $(el).bind('change', function() {
                    _view.setSelectedState();
                });

            });
        },

        setSelectedState:function() {
            _$container.find('ul').attr('style', ulBaseStyle + ulselectedStyle);
            _$container.find('a').attr('style', clearBtnBaseStyle + clearBtnSelectedStyle);
        },

        setUnselectedState:function() {
            _$container.find('ul').attr('style', ulBaseStyle + ulUnselectedStyle);
            _$container.find('a').attr('style', clearBtnBaseStyle + clearBtnUnselectedStyle);

            $('input[name="' + _groupId + '"]').each(function() {
                this.checked = false;
            });

        },

        isSelected : function() {
            return $("input[name='" + _groupId + "']:checked").val() !== '';
        },

        getValueFromGroup : function() {
            return $("input[name='" + _groupId + "']:checked").val();
        }
    };


    //Perform initialization
    _view.createRadioGroup($(this));

    return {
        getValue: _view.getValueFromGroup(),
        isSelected: _view.isSelected()
    }
};
