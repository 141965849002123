$.panel = function(options) {

    var settings = {
        //Indicates if the title performs a highlight animation when changed (when in closed mode)
        highlightClosedTitleChange: false,
        before: false,
        after: false,
        order: 'after',
        title: 'SampleTitle',
        content: '<div align="center"><h1 class="title">SampleContent</h1><br><h2>This is the sample content.</h2><br>' +
                 '<p align="justify">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>' +
                 '<br><p align="justify">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p></div>',
        visible: true,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        open: true,
        anchor: 'bottom', //top,bottom,left,right
        size: 100,
        min_size: 0,
        resize: true,
        expandFn: function() {
            window.console.log('cob-panel: expanding ');
        },
        collapseFn: function() {
            window.console.log('cob-panel: collapsing ');
        },
        resizeFn: function(size) {
            window.console.log('cob-panel: resizing to - ' + size);
        }
    };

    var _$panelWrapper, _$title, _$titleSpan, _$resize, _$content;
    var contentVisible = true;
    var prevY, prevX;

    var _setOpen = function(o, callback) {
        if (contentVisible === o)return;


        if (contentVisible) {
            settings.resizeFn(0);
            if (settings.anchor === 'top' || settings.anchor === 'bottom') {
                _$content.slideUp(400, function() {
                    if (typeof callback === 'function') callback();
                });
            } else {
                _$content.hide();
                if (typeof callback === 'function') callback();
            }
            settings.collapseFn();
            contentVisible = false;
        } else {
            var height = _$content.css('height');
            var width = _$content.css('width');
            if (typeof contentVisible === 'undefined') {
                if (settings.anchor === 'top' || settings.anchor === 'bottom') {
                    _$content.css('display', 'block');
                    settings.resizeFn(+height.substring(0, height.length - 2));
                } else {
                    _$content.css('display', 'inline-block');
                    settings.resizeFn(+width.substring(0, width.length - 2));
                }

            } else {
                if (settings.anchor === 'top' || settings.anchor === 'bottom') {
                    settings.resizeFn(+height.substring(0, height.length - 2));
                    _$content.slideDown(400, function() {
                        if (typeof callback === 'function') callback();
                    });
                } else {
                    settings.resizeFn(+width.substring(0, width.length - 2));
                    _$content.show();
                }
            }
            settings.expandFn();
            contentVisible = true;
        }

    };

    var _setVisible = function(a) {
        _$panelWrapper.css('display', a ? 'block' : 'none');
    };

    var _createAndAppendHtml = function() {
        var wrapperCss = '',
            resizeCss = '',
            contentCss = '',
            titleCss = '';

        switch (settings.anchor) {
            case 'top':
                wrapperCss = "width: 100%;";
                titleCss = 'text-align:center';
                resizeCss = "width:100%; height:3px;";
                contentCss = "overflow: auto;";
                break;
            case 'bottom':
                wrapperCss = "width: 100%;";
                titleCss = 'text-align:center';
                resizeCss = "width:100%; height:3px;";
                contentCss = "overflow: auto;";
                break;
            case 'left':
                wrapperCss = "height:100%";
                titleCss = 'display: inline-block; ' +
                           '-webkit-transform-origin: 0 100%;' +
                           '-webkit-transform: rotate(90deg);' +
                           '-moz-transform-origin: 0 100%;' +
                           '-moz-transform: rotate(90deg);' +
                           //IE 8 uses filter but from IE9 onwards the correct usage is -ms-transform (filter stopped rotating the clickable area)
                           ($.browser.msie && (+$.browser.version) > 8 ?
                            '-ms-transform-origin: 0 100%;-ms-transform: rotate(90deg);' :
                            'filter:progid:DXImageTransform.Microsoft.BasicImage(rotation=1); ');
                resizeCss = "height:100%; width:3px; display: inline-block; vertical-align: middle;";
                contentCss = "overflow: hidden; display: inline-block; vertical-align:top;";
                break;
            case 'right':
                wrapperCss = "height:100%";
                titleCss = 'display: inline-block; ' +
                           '-webkit-transform-origin: 100% 100%;' +
                           '-webkit-transform: rotate(-90deg);' +
                           '-moz-transform-origin: 100% 100%;' +
                           '-moz-transform: rotate(-90deg);' +
                           //IE 8 uses filter but from IE9 onwards the correct usage is -ms-transform (filter stopped rotating the clickable area)
                           ($.browser.msie && (+$.browser.version) > 8 ?
                            '-ms-transform-origin: 100% 100%;-ms-transform: rotate(-90deg);' :
                            'filter:progid:DXImageTransform.Microsoft.BasicImage(rotation=3); ');
                resizeCss = "height:100%; width:3px; display: inline-block; vertical-align: middle;";
                contentCss = "overflow: hidden; display: inline-block; vertical-align:top;";
                break;
        }

        _$panelWrapper = $('<div style="' + wrapperCss + '" class="panel-wrapper"></div>');
        _$title = $('<div class="panel-title-wrapper" style="' + titleCss + '"><span style="cursor: pointer;" class="panel-title"></span></div>');
        _$titleSpan = _$title.find('.panel-title');
        _$resize = $('<div class="panel-resize" style="' + resizeCss + ' z-index:1;"></div>');
        _$content = $('<div class="panel-content-wrapper" style="' + contentCss + '"></div>');


        if (settings.anchor === 'left' || settings.anchor === 'right') {
            _$content.css('width', settings.size + 'px');
        } else {
            _$content.css('height', settings.size + 'px');
        }

        if (settings.anchor === 'bottom' || settings.anchor === 'right') {
            _$panelWrapper.append(_$title).append(_$resize).append(_$content);
        } else {
            _$panelWrapper.append(_$content).append(_$resize).append(_$title);
        }

        _setOpen(settings.open);
        _setVisible(settings.visible);

        //IE HACK
        if (settings.resize) {
            if ($.browser.msie) {
                if (settings.anchor === 'top' || settings.anchor === 'bottom') {
                    _$resize.css('cursor', 'n-resize');
                } else {
                    _$resize.css('cursor', 'e-resize');
                }
            } else {
                if (settings.anchor === 'top' || settings.anchor === 'bottom') {
                    _$resize.css('cursor', 'ns-resize');
                } else {
                    _$resize.css('cursor', 'ew-resize');
                }
            }
        }

        if (settings.before) {
            $(settings.before).before(_$panelWrapper);
        } else if (settings.after) {
            $(settings.after).after(_$panelWrapper);
        } else {
            $('#'+options.cobAppInstanceId).append(_$panelWrapper);
        }
    };

    var _mouseDown = function(e) {
        //stop selections
        _$panelWrapper.css('-moz-user-select', 'none');
        _$panelWrapper.css('-webkit-user-select', 'none');
        _$panelWrapper.bind('onselectstart', _preventDefault);
        _$title.bind('selectstart', _preventDefault);
        _$content.bind('selectstart', _preventDefault);


        prevY = e.clientY;
        $(document).bind('mousemove', _mouseMove);
        $(document).bind('mouseup', _mouseOut);
    };

    var _mouseOut = function() {
        //restore selections
        _$panelWrapper.css('-moz-user-select', '');
        _$panelWrapper.css('-webkit-user-select', '');
        _$panelWrapper.unbind('onselectstart', _preventDefault);
        _$title.unbind('selectstart', _preventDefault);
        _$content.unbind('selectstart', _preventDefault);

        $(document).unbind('mousemove', _mouseMove);
        $(document).unbind('mouseup', _mouseOut);
    };

    var _mouseMove = function(e) {
        var height, width;
        var deltaY = (e.clientY - prevY);
        prevY = e.clientY;
        var deltaX = (e.clientX - prevX);
        prevX = e.clientX;

        switch (settings.anchor) {
            case 'top':
                height = e.clientY;
                if (height < settings.min_size)height = settings.min_size;
                _$content.css('height', height + 'px');
                settings.resizeFn(height);
                break;
            case 'bottom':
                height = document.height - e.clientY;
                if (e.clientY < settings.min_size)width = settings.min_size;
                _$content.css('height', height + 'px');
                settings.resizeFn(height);
                break;
            case 'left':
                width = e.clientX;
                if (e.clientX < settings.min_size)width = settings.min_size;
                _$content.css('width', width + 'px');
                settings.resizeFn(width);
                break;
            case 'right':
                width = document.width - e.clientX;
                if (e.clientX < settings.min_size)width = settings.min_size;
                _$content.css('width', width + 'px');
                settings.resizeFn(width);
                break;
        }
    };

    var _preventDefault = function(e) {
        e.preventDefault();
    };

    var _setTitle = function(text) {
        _$title.find('.panel-title').text(text);
        if (!contentVisible && settings.highlightClosedTitleChange)_$title.find('.panel-title').effect('highlight', {}, 500);
    };

    var _setContent = function(el) {
        _$content.html(el);
    };

    var _init = function() {
        jQuery.extend(settings, options);

        _createAndAppendHtml();

        _setTitle(settings.title);
        _setContent(settings.content);
        _$titleSpan.click(_setOpen);

        if (settings.resize) {
            _$resize.bind('mousedown', _mouseDown);
        }
    };
    _init();

    return{
        setTitle: _setTitle,
        setContent: _setContent,
        setVisible: _setVisible,
        setOpen: _setOpen,
        destroy: function() {
            if (settings.resize) {
                _$resize.unbind('mousedown', _mouseDown);
            }
            _$panelWrapper.remove();
        }
    };
};
