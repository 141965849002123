$.fn.smartautocomplete =
    function (opts) {

        var settings = {
            activate:function (evt) {
                return (evt.keyCode === 32 && evt.ctrlKey) || (evt.keyCode === 52 && evt.shiftKey);
            },
            deactivate:function (evt) {
                return evt.keyCode === 27;
            },
            selection:function (evt) {
                return evt.keyCode === 13;
            },
            limit:10,
            data:{
                keywords:["$auto"],
                $auto:{
                    help:'/localresource/help/auto.html',
                    keywords:["text", "ref", "network"],
                    text:{
                        help:'/localresource/help/auto/text.html',
                        arg:function (idx, code) {
                            return ["txtarg1", "txtarg2"];
                        },
                        keywords:["join"],
                        join:{
                            help:'/localresource/help/auto/text/join.html',
                            arg:function (idx, code) {
                                return ["joinarg1", "joinarg2"];
                            }
                        }
                    }
                }
            }
        };

        var _$el = $(this), _$wrapper, _$ul, _$iframe;

        var active, options, selectedIdx, matchLength, _data, _inArg;

        var _isUp = function (e) {
            return e.keyCode === 38;
        };

        var _isDown = function (e) {
            return e.keyCode === 40;
        };

        var _hover = function (e) {
            var li = $(e.srcElement);
            if (li.index() === selectedIdx)return;

            li.addClass("smartautocomplete-selected");
            options[selectedIdx].removeClass("smartautocomplete-selected");
            selectedIdx = li.index();
            if (_data[options[selectedIdx].text()]){
                _renewIFrame(_data[options[selectedIdx].text()].help);
            }
        };

        var _previous = function () {
            options[selectedIdx--].removeClass("smartautocomplete-selected");
            if (selectedIdx < 0) {
                selectedIdx = options.length - 1;
            }
            options[selectedIdx].addClass("smartautocomplete-selected");
            if (_data[options[selectedIdx].text()]){
                _renewIFrame(_data[options[selectedIdx].text()].help);
            }
        };

        var _next = function () {
            options[selectedIdx++].removeClass("smartautocomplete-selected");
            if (selectedIdx === options.length) {
                selectedIdx = 0;
            }
            options[selectedIdx].addClass("smartautocomplete-selected");
            if (_data[options[selectedIdx].text()]){
                _renewIFrame(_data[options[selectedIdx].text()].help);
            }
        };

        var _select = function () {
            var keyword = _data[options[selectedIdx].text()];
            var currentText1 = _$el.val().substring(0, _$el.caret().start - matchLength);
            var currentText2 = _$el.val().substring(_$el.caret().start + matchLength, _$el.val().length);
            var choice = options[selectedIdx].text();
            if (keyword) {
                if (keyword.arg) {
                    choice += '(';
                } else if (keyword.keywords) {
                    choice += '.';
                }
            }
            _$el.val(currentText1 + choice + currentText2);
            _$el.caret((currentText1 + choice).length, (currentText1 + choice).length);
            if (!(keyword.keywords || keyword.arg) && !_inArg)
                _deactivate();
            else
                _activate();
        };

        var _keydown = function (e) {
            if (selectedIdx >= 0 && active) {
                if (_isUp(e)) {
                    _previous();
                    e.preventDefault();
                } else if (_isDown(e)) {
                    _next();
                    e.preventDefault();
                } else if (settings.selection(e)) {
                    _select();
                }
            }
        };

        var _keyup = function (e) {
            if (_isUp(e) || _isDown(e))return;
            if (settings.deactivate(e)) {
                _deactivate();
            } else if (active || settings.activate(e)) {
                _activate(e);
            }
        };

        var _init = function () {
            jQuery.extend(settings, opts);
            _$el.bind('keydown', _keydown);
            _$el.bind('keyup', _keyup);
            _$el.click(function (e) {
                if (active)_activate(e);
            });
            _$wrapper = $('<div style="position: absolute;" class="hidden"></div>');
            _$ul = $('<ul class="smartautocomplete" style="display: inline-block;vertical-align: top;"></ul>');
            _$iframe = $('<iframe class="smartautocomplete-help" style="display: inline-block;" width="400px" height="300px" src=""/>');
            _$wrapper.append(_$ul);
            _$wrapper.append(_$iframe);
            $('#'+opts.cobAppInstanceId).append(_$wrapper);
        };
        _init();

        var _activate = function (e) {
            active = true;
            _$wrapper.removeClass('hidden');
            var txt = _$el.val().substring(0, _$el.caret().start);
            var searchText;
            if (txt.lastIndexOf("$") >= 0) {
                searchText = txt.substring(txt.lastIndexOf("$"), txt.length).trim();
            }
            _$ul.html('');
            if (!searchText) {
                _deactivate();
            } else {
                _$wrapper.css('left', _$el.offset().left);
                _$wrapper.css('top', _$el.offset().top + _$el.height() + 5);
                selectedIdx = -1;
                options = [];
                var matchingKeywords = _getMatchingKeywords(searchText);
                if (matchingKeywords.length > 0) {
                    $.each(matchingKeywords, function (idx, el) {
                        var li = $('<li class="smartautocomplete-choice">' + el + '</li>');
                        li.mouseover(_hover);
                        li.click(function (e) {
                            e.preventDefault();
                            _select();
                        });
                        if (idx === 0) {
                            selectedIdx = 0;
                            li.addClass('smartautocomplete-selected');
                            if (_data[el]) {
                                _renewIFrame(_data[el].help);
                            } else {
                                _renewIFrame(_data.help);
                            }
                        }
                        options.push(li);
                        _$ul.append(li);
                    });
                } else {
                    if (searchText[searchText.length - 1] === (')') && !_data.keywords) {
                        _deactivate();
                    } else {
                        _renewIFrame(_data.help);
                    }
                }
            }
        };

        var _getMatchingKeywords = function (searchText) {
            var matches = [];
            var keywords = searchText.split(/[.\(]/);

            _data = settings.data;

            for (var i = 0; i < keywords.length - 1; i++) {
                var keyword = keywords[i].replace(/\(.*\)?/g, '');
                if (keyword[keyword.length - 1] === ')')continue;
                _data = _data[keyword];
                if (!_data) break;
            }
            if (/[(,][\w ]*$/.test(searchText.trim()) && _data.arg) {
                _inArg = true;
                if (_data.arg) {
                    var argKeywords = searchText.trim().match(/[(,][\w ]*/g);
                    var argKeyword = argKeywords[argKeywords.length - 1].substring(1);
                    matchLength = argKeyword.length;
                    _data.arg(argKeywords.length - 1, searchText).forEach(function (el) {
                        if (el.indexOf(argKeyword) === 0) {
                            if (settings.limit && matches.length < settings.limit)
                                matches.push(el);
                        }
                    });
                }
            } else if (_data.keywords) {
                _inArg = false;
                matchLength = keywords[keywords.length - 1].length;
                $.each(_data.keywords, function (idx, el) {
                    if (el.indexOf(keywords[keywords.length - 1]) === 0) {
                        if (settings.limit && matches.length < settings.limit)
                            matches.push(el);
                    }
                });
            }
            return matches.sort();
        };

        var _deactivate = function () {
            active = false;
            _$ul.html('');
            _$wrapper.addClass('hidden');
        };

        //we need to replace the iframe instead of just change its src otherwise browser history will be
        // changed and our navigation will be affected (the more we changed the src, the more we had to click
        // the buttons that perform navigation - for eg Save or Cancel btns)
        // http://stackoverflow.com/questions/9945587/how-to-prevent-iframe-pages-being-inserted-into-ie-browsing-history
        var _renewIFrame = function(newSrc){
            var frm = document.createElement("iframe");
            frm.src = newSrc  + "?wmode=transparent";
            _$iframe.replaceWith(frm);
        };
    };
