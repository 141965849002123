$.fn.timer = function(props) {

    const ONE_SECOND_MILLIS = 1000;

    const _startTime = -ONE_SECOND_MILLIS;
    let _elapsedTime = -ONE_SECOND_MILLIS,
        _countingEnable = false;

    const view = {
        appendHtml: function(el) {
            const html = '<div id="cob-timer">' +
                       '<span id="cob-timer-hour">00</span><span class="cob-timer-splitter">:</span>' +
                       '<span id="cob-timer-minute">00</span><span class="cob-timer-splitter">:</span>' +
                       '<span id="cob-timer-second">00</span>' +
                       '</div>';

            $(el).append(html)
        },

        updateTime: function(elapsedTime) {
            $("div#cob-timer span#cob-timer-hour").text(sprintf("%2d", Math.floor(elapsedTime / 3600000))
                .replace(' ', '0'));

            $("div#cob-timer span#cob-timer-minute").text(sprintf("%2d", Math.floor((elapsedTime / 60000) % 60))
                .replace(' ', '0'));

            $("div#cob-timer span#cob-timer-second").text(sprintf("%2d", Math.floor((elapsedTime / 1000) % 60))
                .replace(' ', '0'));
        }
    };

    if (props.display) {
        view.appendHtml($(this));
    }

    const count = function() {
        if (_countingEnable) {
            window.setTimeout(function() {
                count()
            }, ONE_SECOND_MILLIS);
        }
        _elapsedTime += ONE_SECOND_MILLIS;

        view.updateTime(_elapsedTime);

        handleJobs();
    };

    const handleJobs = function() {
        props.jobs.map(function(job) {
            let frequency = (typeof job.frequency === 'function'
                             ? job.frequency()
                             : job.frequency);

            if (_elapsedTime % (frequency * ONE_SECOND_MILLIS) == 0) {
                if (typeof job.action === 'function') {
                    job.action();
                }
            }
        });
    };

    const _reset = function() {
        _elapsedTime = _startTime;
    };

    return {
        start: function(initialTime) {
            if(props.resetOnStart) _reset();

            if (initialTime) {
                _elapsedTime = initialTime;
            }
            _countingEnable = true;
            count();
        },
        updateTime: function(elapsedTime) {
            _elapsedTime = Math.floor(elapsedTime / ONE_SECOND_MILLIS) * ONE_SECOND_MILLIS;
            view.updateTime(elapsedTime);
        },
        isRunning: function() {
            return _countingEnable;
        },

        stop: function() {
            _countingEnable = false;
            if(props.resetOnStop) _reset();
        },
        reset: _reset
    }
};
