$.fn.actionConfirmation =
    function(buttonSelector, confirmButtonSelector, options) {

        var settings = {
            buttonAnimationClass:'.none',
            buttonsHideClass:'invisible',
            otherButtonsSelector:'.none',
            otherConfirmButtonsSelector:'none',

            confirmationFn:function() {
                window.console.log('confirming action of button ' + buttonSelector);
            },
            effect:{
                name:'pulsate',
                opts:{times:1},
                speed:500
            }
        }

        var _$that = this,
            _stateKey = 'confirmation-' + buttonSelector + "-" + confirmButtonSelector,
            _$button = $(_$that).find(buttonSelector),
            _$confirmButton = $(_$that).find(confirmButtonSelector),

            enabled = false;

        var _setState = function(state) {
            if (state === _$that.data(_stateKey))
                return;

            if (state) {
                _$button.removeClass(settings.buttonAnimationClass);
                _$that.find(settings.otherConfirmButtonsSelector).removeClass(settings.buttonsHideClass);

                _$confirmButton.addClass('hidden');
                _$that.find(settings.otherButtonsSelector).removeClass('hidden');

                _$that.effect(settings.effect.name, settings.effect.opts, settings.effect.speed);
            } else {
                _$button.addClass(settings.buttonAnimationClass);
                _$that.find(settings.otherConfirmButtonsSelector)
                    .filter(':not(' + buttonSelector + ')')
                    .addClass(settings.buttonsHideClass);

                _$confirmButton.removeClass('hidden');
                _$that.find(settings.otherButtonsSelector).addClass('hidden');

                _$that.effect(settings.effect.name, settings.effect.opts, settings.effect.speed);
            }

            _$that.data(_stateKey, state);
        }

        var _enable = function(confirmationHidden) {
            if (enabled) return;
            enabled = true;

            if (confirmationHidden !== 'undefined' && confirmationHidden !== null) {
                _setState(confirmationHidden);
            }

            _$button.click(function() {
                var inState = _$that.data(_stateKey);
                _setState(!inState);
            });
        };

        var _disable = function(confirmationHidden) {
            if (!enabled)return;
            enabled = false;

            if (confirmationHidden !== 'undefined' && confirmationHidden !== null) {
                _setState(confirmationHidden);
            }

            _$button.unbind('click');
        };

        var _init = function() {
            jQuery.extend(settings, options);

            _$confirmButton.click(settings.confirmationFn);
            _$that.data(_stateKey, true);
            _enable(null);
        };
        _init();

        return {
            getState:function() {
                _$that.data(_stateKey);
            },
            setState:_setState,
            enable:_enable,
            disable:_disable
        }
    };
