/************************************************
 *  jQuery iphoneSwitch plugin                   *
 *                                               *
 *  Author: Daniel LaBare                        *
 *  Date:   2/4/2008                             *
 ************************************************/
$.fn.iphoneSwitch = function(options) {
    var _this = this;

    // define default settings
    var settings = {
        mouse_over: 'pointer',
        mouse_out:  'default',
        switch_on_container_path: 'images/iphone_switch_container_on.png',
        switch_off_container_path: 'images/iphone_switch_container_off.png',
        switch_path: 'images/iphone_switch.png',
        switch_clear_path: 'images/iphone_switch_clear.png',
        container_height: 22,
        switch_height: 20,
        switch_width: 70,
        switch_label: 48
    };

    if (options) {
        jQuery.extend(settings, options);
    }

    var labels = this.attr('labels');

    if (labels) {
        settings.switch_path = settings.switch_clear_path;
        settings.switch_on_container_path = settings.switch_off_container_path;
        labels = labels.split("|");
    }

    var state = 'off';
    var elemPlaceholder = jQuery(this);
    var container;
    var image;

    var switched_off_callback;
    var switched_on_callback;

    // make the container
    container = jQuery(

        '<div class="iphone_switch_container" ' +
            'style="height:' + settings.container_height + 'px; ' +
            'width:auto!important'+
            'position: relative; overflow: hidden">' +
            '</div>');

    // make the switch image based on starting state
    image = jQuery(
        (labels ? '<span class="offLabel" style="font-size:1.2em; color:#39C; padding: 3px; margin-left: 3px; margin-right: 3px; vertical-align:bottom;">' + labels[0] + '</span>' : '') +
            '<img class="iphone_switch"' +
            ' style="height:' + settings.switch_height +
            'px; width:' + settings.switch_width + 'px; ' +
            'background-image:url(' + settings.switch_path + '); ' +
            'background-repeat:none; ' +
            'background-position:' + (state == 'on' ? 0 : -39) + 'px" ' +
            'src="' + (state == 'on' ? settings.switch_on_container_path : settings.switch_off_container_path) + '" />' +
            '</img>' +
            (labels ? '<span class="onLabel" style="font-size:1.2em; color:#AAA; padding: 3px; margin-left: 3px; margin-right: 3px; vertical-align:bottom;">' + labels[1] + '</span>' : ''));

    // insert into placeholder
    elemPlaceholder.html(jQuery(container).html(jQuery(image))).click(function() {
        if (state === 'on') {
            setStateOff();
        }
        else {
            setStateOn();
        }
    });

    elemPlaceholder.mouseover(function() {
        elemPlaceholder.css("cursor", settings.mouse_over);
    });

    elemPlaceholder.mouseout(function() {
        elemPlaceholder.css("background", settings.mouse_out);
    });

    // click handling
    function setStateOff() {
        elemPlaceholder.find('.iphone_switch').animate({backgroundPosition: -39}, "fast", function() {
            elemPlaceholder.attr('src', settings.switch_off_container_path);

            if (switched_off_callback) {
                switched_off_callback();
            }
        });
        state = 'off';
        _this.find('.offLabel').css('color', '#39C');
        _this.find('.onLabel').css('color', '#AAA');
    }

    function setStateOn() {
        elemPlaceholder.find('.iphone_switch').animate({backgroundPosition: 0}, "fast", function() {
            if (switched_on_callback) {
                switched_on_callback();
            }
        });
        elemPlaceholder.find('.iphone_switch').attr('src', settings.switch_on_container_path);
        state = 'on';
        _this.find('.offLabel').css('color', '#AAA');
        _this.find('.onLabel').css('color', '#39C');
    }

    return {
        getState: function() {
            return state === 'on';
        },
        setState : function(value) {
            if (value) {
                setStateOn();
            } else {
                setStateOff()
            }
        }
    }
};

