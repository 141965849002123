//## Jquery Confirmation plugin
//
// This plugin allows that a certain request must have confirmation.
// It will show the confirmation buttons when the element is clicked.
//
//**Configuration:**
//### confirm:
//    *    **selector**: a jquery selector
//    *    **callback**: when user confirm, the callback will be invoked
//
//### cancel
//    *     **selector**: a jquery selector
//    *     **callback**: when user cancel, the callback will be invoked
//
//------------------
//**Usage example:**
//
//      $.find(".delete-link").confirmation({
//          confirm: {
//              selector: ".dialog .confirm",
//              callback: function() {
//                  // something happens here
//              }
//          },
//          cancel: {
//              selector: ".dialog .cancel",
//              callback: function{}{console.log("cancel made");}
//          }
//      }
//

(function($) {

    $.fn.confirmation = function(opts) {

        var _$this = this;

        var defaultOpts = {
            "confirm": {
                "selector": "",
                "callback": function() {}
            },

            "cancel": {
                "selector": "",
                "callback": function() {}
            }
        };

        // Merge our default options with the ones that are passed
        var options = $.extend(defaultOpts, opts || {});

        var _init = function() {

            var cancelElem = $(options.cancel.selector),
                confirmElem = $(options.confirm.selector);

            _$this.click(function() {
                cancelElem.show();
                cancelElem.removeClass("hidden");

                confirmElem.show();
                confirmElem.removeClass("hidden");
            });

            // handle the cancel callback
            cancelElem.click(function() {
                cancelElem.addClass("hidden");
                confirmElem.addClass("hidden");

                if (typeof(options.cancel.callback) === "function") {
                    options.cancel.callback();
                }
            });

            //  // handle the confirmation callback
            confirmElem.click(function() {
                if (typeof(options.confirm.callback) === "function") {
                    options.confirm.callback();
                }
            });

        };

        _init();
    };

}(jQuery));